.react-calendar {
    width: 260px;
    max-width: 100%;
    background: white;
    font-family: Inter, sans-serif;
    line-height: 1.5em;
    border: none
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 36px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 30px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
    border-radius: 10px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
    border-radius: 10px;

}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    font-weight: lighter;
    text-decoration: none !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
}

.react-calendar__month-view__days__day--weekend {
    /*color: #d10000;*/
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 2.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 12px;
    border-radius: 10px;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    /*background: #ffff76;*/
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    /*background: #ffffa9;*/
}

.react-calendar__tile--hasActive {
    background: #8C0CF0;
    color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #8C0CF0;
}

.react-calendar__tile--active {
    background: #8C0CF0;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #8C0CF0;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
